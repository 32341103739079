import React, { useReducer } from 'react'
import SchedulePickupContext from './schedulePickupContext'
import SchedulePickupReducer from './schedulePickupReducer'
import { apiCall } from '../../../common/api'
import { response } from '../../common'
import {
    CLEAR_ALL_MESSAGES,
    CLEAR_RESPONSE,
    GET_ALL_LOCATIONS,
    GET_ALL_MESSAGES,
    GET_ALL_TIMINGS,
    RESPONSE_STATUS,
    SET_DEFAULT_TIMINGS,
} from './schedulePickupTypes'

const SchedulePickupState = (props) => {
    const initialState = {
        responseStatus: null,
        all_timings: {
            default_timings: [],
            scheduled_timings: [],
            all_locations: [],
        },
        all_messages: [],
    }

    const [state, dispatch] = useReducer(SchedulePickupReducer, initialState)
    let resp = new response(dispatch, RESPONSE_STATUS)
    const baseUrl = 'plugin/scheduling/communication/connect'

    const getAllTimings = async (formData) => {
        try {
            const from = 'getAllTimings'
            const [res] = await Promise.all([
                apiCall('post', `getnewscheduletiming`, formData, '', baseUrl),
            ])
            if (res.data.status === 'success') {
                dispatch({
                    type: GET_ALL_TIMINGS,
                    payload: res.data.data?.message,
                })
            } else {
                resp.commonErrorResponse(from)
            }
        } catch (err) {
            dispatch({
                type: RESPONSE_STATUS,
                payload: 'Something went wrong!',
            })
        }
    }
    const updateDefaultTimings = (payload) => {
        dispatch({
            type: SET_DEFAULT_TIMINGS,
            payload,
        })
    }
    const getAllLocations = async (formData) => {
        try {
            const from = 'getAllLocations'
            const [res] = await Promise.all([
                apiCall('post', `getalllocations`, formData, '', baseUrl),
            ])
            if (res.data.status === 'success') {
                dispatch({
                    type: GET_ALL_LOCATIONS,
                    payload: res.data.data?.message,
                })
            } else {
                resp.commonErrorResponse(from)
            }
        } catch (err) {
            dispatch({
                type: RESPONSE_STATUS,
                payload: 'Something went wrong!',
            })
        }
    }

    const addSchedule = async (formData) => {
        try {
            const from = 'addSchedule'
            const [res] = await Promise.all([
                apiCall('post', `addnewscheduletiming`, formData, '', baseUrl),
            ])
            if (res.data.status === 'success') {
                resp.commonResponse(
                    {
                        status: 'success',
                        message: res.data.data,
                    },
                    from,
                )
            } else {
                resp.commonResponse(
                    {
                        status: 'error',
                        message: res?.data?.data?.message
                            ? res.data.data.message
                            : 'Something went wrong!',
                    },
                    from,
                )
            }
        } catch (err) {
            dispatch({
                type: RESPONSE_STATUS,
                payload: 'Something went wrong!',
            })
        }
    }
    const addCheckIn = async (formData) => {
        try {
            const from = 'addCheckIn'
            const [res] = await Promise.all([apiCall('post', `addCheckIn`, formData, '', baseUrl)])
            if (res.data.status === 'success') {
                resp.commonResponse(
                    {
                        status: 'success',
                        message: res.data.data,
                    },
                    from,
                )
            } else {
                resp.commonErrorResponse(from)
            }
        } catch (err) {
            dispatch({
                type: RESPONSE_STATUS,
                payload: 'Something went wrong!',
            })
        }
    }

    const getAllMessages = async (formData) => {
        try {
            const from = 'getAllMessages'
            const [res] = await Promise.all([
                apiCall('post', `viewnewmessagesIO`, formData, '', baseUrl),
            ])
            if (res.data.status === 'success') {
                dispatch({
                    type: GET_ALL_MESSAGES,
                    payload: res.data.data.message.messages,
                })
            } else {
                resp.commonErrorResponse(from)
            }
        } catch (err) {
            dispatch({
                type: RESPONSE_STATUS,
                payload: 'Something went wrong!',
            })
        }
    }
    const composeMessage = async (formData) => {
        try {
            const from = 'composeMessage'
            const [res] = await Promise.all([
                apiCall('post', `createnewmsg`, formData, '', baseUrl),
            ])
            if (res.data.status === 'success') {
                res.data.message = res.data.data.message
                resp.commonResponse(res.data, from)
            } else if (res.data.status === 'error') {
                res.data.message = res.data.data.message
                resp.commonResponse(res.data, from)
            } else {
                resp.commonErrorResponse(from)
            }
        } catch (err) {
            dispatch({
                type: RESPONSE_STATUS,
                payload: 'Something went wrong!',
            })
        }
    }
    const clearAllMessages = () => {
        dispatch({
            type: CLEAR_ALL_MESSAGES,
            payload: [],
        })
    }

    const clearResponse = () =>
        dispatch({
            type: CLEAR_RESPONSE,
        })
    return (
        <SchedulePickupContext.Provider
            value={{
                responseStatus: state.responseStatus,
                all_timings: state.all_timings,
                all_messages: state.all_messages,
                updateDefaultTimings,
                getAllLocations,
                composeMessage,
                addCheckIn,
                addSchedule,
                getAllTimings,
                getAllMessages,
                clearAllMessages,
                clearResponse,
            }}
        >
            {props.children}
        </SchedulePickupContext.Provider>
    )
}

export default SchedulePickupState
