import React, { useEffect, useMemo, useRef, useState } from 'react'
import { format, addDays, subDays, getWeek, addWeeks, subWeeks, lastDayOfWeek } from 'date-fns'
import { Button, Radio, RadioGroup, FormControlLabel, FormControl } from '@material-ui/core'
import moment from 'moment'
import { useContext } from 'react'
import schedulePickupContext from '../../../Product/context/plugin/schedulePickup/schedulePickupContext'
import './Scheduler.css'
import { convertTime12to24 } from '../../commonFunctions'

export default function Scheduler({
    selectedDate,
    setSelectedDate,
    selectedTime,
    setSelectedTime,
    pickupData,
    invoice,
}) {
    var count = 1
    function addDays(date, day) {
        let new_date = new Date(date.getTime())
        new_date.setDate(new_date.getDate() + day)
        return new_date
    }
    const { all_timings } = useContext(schedulePickupContext)
    const [currentMonth, setCurrentMonth] = useState(new Date())
    const [startDate, setStartDate] = useState(
        global.pluginConfiguration?.limit_pickup_days?.enable == 1
            ? addDays(new Date(invoice?.date_closed?.split('T')[0]), 1)
            : new Date(),
    )
    const [disabledBack, setDisabledBack] = useState(true)

    const [availableSlots, setAvailableSlots] = useState([])
    const [tempWeekDays, setTempWeekDays] = useState([])
    const [first, setFirst] = useState(false)
    const [dayOfWeek, setDayOfWeek] = useState('')
    // const countRef = useRef(count)
    // useEffect(() => {
    //     countRef.current = count
    // })
    const [dateLimit, setDateLimit] = useState({
        start_date: new Date(),
        end_date: new Date(),
    })
    const [timings, setTimings] = useState({
        default: [],
        scheduled: [],
    })
    const dummyResponse = [
        {
            Day: 'Monday',
            Status: 'Closed',
            Day_Times: [],
        },

        {
            Day: 'Tuesday',
            Status: 'Open',
            Day_Times: [
                {
                    start_time: '10:00 AM',
                    end_time: '06:00 PM',
                },
            ],
        },

        {
            Day: 'Wednesday',
            Status: 'Open',
            Day_Times: [
                {
                    start_time: '10:00 AM',
                    end_time: '06:00 PM',
                },
            ],
        },

        {
            Day: 'Thursday',
            Status: 'Open',
            Day_Times: [
                {
                    start_time: '10:00 AM',
                    end_time: '06:00 PM',
                },
            ],
        },

        {
            Day: 'Friday',
            Status: 'Open',
            Day_Times: [
                {
                    start_time: '10:00 AM',
                    end_time: '06:00 PM',
                },
            ],
        },

        {
            Day: 'Saturday',
            Status: 'Open',
            Day_Times: [
                {
                    start_time: '10:00 AM',
                    end_time: '06:00 PM',
                },
            ],
        },

        {
            Day: 'Sunday',
            Status: 'Open',
            Day_Times: [
                {
                    start_time: '10:00 AM',
                    end_time: '06:00 PM',
                },
            ],
        },
    ]

    const handleDateChange = (event) => {
        setFirst(true)
        setSelectedTime(null)
        setSelectedDate(event.target.value)

        let formattedDate = moment(event.target.value, 'ddd DD MMM')
        setDayOfWeek(formattedDate.format('dddd'))
    }

    const handleTimeChange = (event) => {
        setFirst(true)
        setSelectedTime(event.target.value)
    }

    const changeWeekHandle = (btnType) => {
        const today = moment()
        const offset = btnType === 'prev' ? -7 : 7
        const newStartDate = addDays(startDate, offset)
        setStartDate(newStartDate)

        if (moment(newStartDate).isSame(today, 'day')) {
            setDisabledBack(true)
        } else {
            setCurrentMonth(
                btnType === 'prev' ? subWeeks(currentMonth, 1) : addWeeks(currentMonth, 1),
            )
            setDisabledBack(false)
        }
    }

    const renderWeekDays = () => {
        const dateFormat = 'EEE d MMM yyyy'
        const weekDays = []
        const temp_week_days = []
        const today = moment()
        // count = 1
        let temp_count = 1
        for (let i = 0; i < 7; i++) {
            const selDay = addDays(startDate, i)
            const formattedDate = format(selDay, dateFormat)
            var day = formattedDate?.split(' ')[0]
            let date = formattedDate?.split(' ')[1]
            let month = formattedDate?.split(' ')[2]

            if (
                moment(selDay).isBefore(today, 'day') &&
                global.pluginConfiguration?.limit_pickup_days?.enable != 1
            ) {
                console.log('Older Date')
            } else {
                if (global.pluginConfiguration?.limit_pickup_days?.enable == 1) {
                    if (
                        temp_count <=
                        parseInt(global.pluginConfiguration?.limit_pickup_days?.client_key)
                    ) {
                        if (
                            timings?.default[0]?.general?.find((ele) => ele.Day.includes(day))
                                ?.Status === 'Closed'
                        )
                            continue
                        else {
                            // temp_week_days.push(day)
                            console.log(day, 'dayday')
                            temp_count++
                        }
                    } else {
                        console.log(temp_count)
                        break
                    }
                }
                let disable = timings?.default[0]?.general?.find((ele) => ele.Day.includes(day))
                    ? timings?.default[0]?.general?.find((ele) => ele.Day.includes(day))?.Status ===
                      'Closed'
                        ? true
                        : new Date(selDay) < new Date(new Date().toJSON().split('T')[0])
                        ? true
                        : false
                    : false
                console.log(disable, 'disabledisabledisabledisable')
                weekDays.push(
                    <FormControlLabel
                        className={`${formattedDate == selectedDate ? 'selected' : ''}`}
                        key={i}
                        value={formattedDate}
                        control={<Radio />}
                        label={
                            <div className="dayPreview">
                                <span>{day}</span>
                                <span>{date}</span>
                                <span>{month}</span>
                            </div>
                        }
                        disabled={disable}
                    />,
                )
                if (!disable) {
                    temp_week_days.push(day)
                }
                // if (
                //     timings?.default[0]?.general?.find((ele) => ele.Day.includes(day))?.Status !==
                //         'Closed' &&
                //     count <= parseInt(global.pluginConfiguration?.limit_pickup_days?.client_key)
                // ) {
                //     count++
                // }
            }
        }

        if (global.pluginConfiguration?.limit_pickup_days?.enable == 1 && !first) {
            console.log(
                temp_week_days,
                'temp_week_days',
                all_timings?.default_timings[0]?.general?.find((ele) =>
                    ele.Day?.includes(temp_week_days[0]),
                )?.Day,
            )
            if (!temp_week_days.length) {
                setSelectedDate(null)
            } else {
                setSelectedDate(
                    format(
                        pickupData?.booking_date
                            ? new Date(pickupData?.booking_date)
                            : getDates(
                                  1,
                                  all_timings?.default_timings[0]?.general?.find((ele) =>
                                      ele.Day?.includes(temp_week_days[0]),
                                  )?.Day,
                              ),
                        'EEE d MMM yyyy',
                    ),
                )
                setSelectedTime(
                    pickupData?.booking_date
                        ? format(
                              pickupData?.booking_date
                                  ? new Date(pickupData?.booking_date)
                                  : getDates(
                                        1,
                                        all_timings?.default_timings[0]?.general?.find((ele) =>
                                            ele.Day?.includes(
                                                temp_week_days[temp_week_days.length - 1],
                                            ),
                                        )?.Day,
                                    ),
                              'hh:mm a',
                          )
                        : null,
                )
            }
        }

        return (
            <FormControl className="schedulerDate">
                <RadioGroup
                    aria-label="demo-controlled-radio-buttons-group"
                    name="controlled-radio-buttons-group"
                    value={selectedDate}
                    onChange={handleDateChange}
                >
                    {weekDays}
                </RadioGroup>
            </FormControl>
        )
    }

    useMemo(() => {
        renderWeekDays()
    }, [startDate])
    console.log(invoice, 'invoice+++++++')
    const generateTimeSlots = () => {
        let day = selectedDate?.split(' ')[0]
        let sTime =
            timings?.default[0]?.general?.find((ele) => ele.Day.includes(day))?.Day_Times[0]
                ?.start_time || '12:00 AM'
        let eTime =
            timings?.default[0]?.general?.find((ele) => ele.Day.includes(day))?.Day_Times[0]
                ?.end_time || '11:59 PM'
        console.log('generateTimeSlots', day, sTime, eTime)
        // if (
        //     localStorage.getItem('Browsertype') == 'Safari' ||
        //     localStorage.getItem('Browsertype') == 'Mobile Safari'
        // ) {
        sTime = convertTime12to24(sTime)
        eTime = convertTime12to24(eTime)
        // }
        console.log(sTime, eTime, timings, 'timings')
        const sDate = new Date(`2000-01-01 ${sTime}`)
        const eDate = new Date(`2000-01-01 ${eTime}`)

        const timeSlots = []

        const interval =
            60 *
            (timings?.default[0]?.pickup_minutes != 0 ? timings?.default[0]?.pickup_minutes : 5) *
            1000
        let currentSlot = sDate

        while (currentSlot <= eDate) {
            const slotStart = currentSlot.toLocaleTimeString([], {
                hour: '2-digit',
                minute: '2-digit',
            })
            timeSlots.push(slotStart)
            currentSlot = new Date(currentSlot.getTime() + interval)
        }
        return timeSlots
    }

    useEffect(() => {
        setAvailableSlots(generateTimeSlots())
    }, [selectedDate, timings])

    function getDates(k, day = 'Monday') {
        console.log(day, 'dayyyy')
        const day_num = [
            'sunday',
            'monday',
            'tuesday',
            'wednesday',
            'thursday',
            'friday',
            'saturday',
        ].indexOf(day.toLowerCase()) // converting day name to number

        let new_day = startDate
        while (new_day.getDay() !== day_num) {
            new_day = addDays(new_day, 1)
        }

        return addDays(new_day, 0)
    }

    useEffect(() => {
        setTimings({
            ...timings,
            default: all_timings?.default_timings,
            scheduled: all_timings?.scheduled_timings,
        })
        if (global.pluginConfiguration?.limit_pickup_days?.enable != 1) {
            setSelectedDate(
                format(
                    pickupData?.booking_date
                        ? new Date(pickupData?.booking_date)
                        : getDates(
                              1,
                              all_timings?.default_timings[0]?.general?.find(
                                  (ele) => ele.Status === 'Open',
                              )?.Day,
                          ),
                    'EEE d MMM yyyy',
                ),
            )
            setSelectedTime(
                pickupData?.booking_date
                    ? format(
                          pickupData?.booking_date
                              ? new Date(pickupData?.booking_date)
                              : getDates(
                                    1,
                                    all_timings?.default_timings[0]?.general?.find(
                                        (ele) => ele.Status === 'Open',
                                    )?.Day,
                                ),
                          'hh:mm a',
                      )
                    : null,
            )
        }
    }, [all_timings])
    return (
        <div className="scheduler">
            {global.pluginConfiguration?.limit_pickup_days?.enable == 1 ? null : (
                <div className="schedulerTop">
                    Calendar
                    <div className="schedulerActions">
                        <Button
                            className="schedulerMove"
                            onClick={() => changeWeekHandle('prev')}
                            disabled={disabledBack}
                        >
                            <span className="material-icons">navigate_before</span>
                        </Button>

                        <Button className="schedulerMove" onClick={() => changeWeekHandle('next')}>
                            <span className="material-icons">navigate_next</span>
                        </Button>
                    </div>
                </div>
            )}

            <div className="schedulerMain">
                {renderWeekDays()}
                <div className="schedulerTime">
                    <h4 className="stTitle">{selectedDate}</h4>
                    {selectedDate ? (
                        <div className="timeUnits">
                            <FormControl>
                                <RadioGroup
                                    aria-label="demo-controlled-radio-buttons-group"
                                    name="controlled-radio-buttons-group"
                                    value={selectedTime}
                                    onChange={handleTimeChange}
                                >
                                    {availableSlots?.map((d, i) => (
                                        <FormControlLabel
                                            key={i}
                                            value={d}
                                            control={
                                                <Radio
                                                    disabled={
                                                        new Date(
                                                            `${moment().format(
                                                                'YYYY-MM-DD',
                                                            )} ${convertTime12to24(d)}`,
                                                        ) <
                                                            new Date(
                                                                `${moment().format(
                                                                    'YYYY-MM-DD HH:mm',
                                                                )}`,
                                                            ) &&
                                                        new Date(selectedDate).getDate() <=
                                                            new Date().getDate() &&
                                                        new Date(selectedDate).getMonth() <=
                                                            new Date().getMonth()
                                                    }
                                                />
                                            }
                                            label={d}
                                        />
                                    ))}
                                </RadioGroup>
                            </FormControl>
                        </div>
                    ) : global.pluginConfiguration?.limit_pickup_days?.enable == 1 ? (
                        <p>Your Scheduling Time Is Over!</p>
                    ) : null}
                </div>
            </div>
        </div>
    )
}
